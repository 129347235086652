import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { CollectionsProps, CollectionsType } from '.';
import { URLS } from '../../api';
import { axiosBaseQuery } from '../../helpers';

export const сollectionAPI = createApi({
  reducerPath: 'сollectionAPI',
  tagTypes: ['Collections'],
  baseQuery: axiosBaseQuery,
  endpoints: (build) => ({
    createCollection: build.mutation({
      query: ({ formData }) => ({
        url: URLS.collections,
        method: 'post',
        data: formData,
        headers: { ContentType: 'multipart/form-data' },
      }),
      invalidatesTags: () => ['Collections'],
    }),
    editCollection: build.mutation({
      query: ({ formData, id }) => ({
        url: `${URLS.collections}/${id}`,
        method: 'put',
        data: formData,
        headers: { ContentType: 'multipart/form-data' },
      }),
      invalidatesTags: () => ['Collections'],
    }),
    deleteCollection: build.mutation({
      query: ({ id }) => ({
        url: `${URLS.collections}/${id}`,
        method: 'delete',
      }),
      invalidatesTags: () => ['Collections'],
    }),
    getCollections: build.query<CollectionsType, CollectionsProps>({
      query: ({ page, perPage, url }) => ({
        url: !url
          ? `${URLS.collections}?page_number=${page}&page_size=${perPage}`
          : `${URLS.collections}?page_number=${page}&page_size=${perPage}&${url}`,
        method: 'get',
      }),
      providesTags: () => ['Collections'],
    }),
  }),
});

export const {
  useCreateCollectionMutation,
  useEditCollectionMutation,
  useDeleteCollectionMutation,
  useGetCollectionsQuery,
} = сollectionAPI;
